import { ElementRef, FC, useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import OTPInput from '../../../components/shared/form-control/OTPInput';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import { useCountdown } from '../../../hooks/useCountdown';
import AuthService from '../../../services/AuthService';
import DateUtils from '../../../utils/DateUtils';
import { FlowProps } from './LoginFlow';
import { ModalContext } from '../../../contexts/ModalContext';
import ConfirmationModal from '../../../components/shared/modal/variants/ConfirmationModal';

const TIMEOUT_LENGTH = 5 * 60; // 5 minutes

const TOTPLogin: FC<FlowProps> = (props) => {
  const { next } = props;
  const [appModalOpen, setAppModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const otpRef = useRef<ElementRef<typeof OTPInput>>(null);
  const timeLeft = useCountdown(TIMEOUT_LENGTH);

  const toasts = useToasts();
  const { t } = useTranslation('auth');

  const onComplete = useCallback(
    (pin: string) => {
      setLoading(true);
      AuthService.verify2FA(pin)
        .then(() => {
          next('done');
        })
        .catch(() => {
          otpRef.current?.clearOtp();
          toasts.addToast({
            title: t('otp.toasts.invalid.title'),
            type: ToastType.ERROR,
            description: t('otp.toasts.invalid.description'),
            expiresInMs: 10000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [next, t, toasts],
  );

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('otp.heading')}</Heading>
      <div>{t('otp.subheading')}</div>
      <div onClick={() => setAppModalOpen(true)} className="my-4 cursor-pointer font-medium hover:underline" data-cy="app-modal-trigger">
        {t('otp.app-modal.trigger')}
      </div>
      <OTPInput onComplete={onComplete} disabled={loading || timeLeft <= 0} ref={otpRef} />
      <div className="w-96">
        <div className="text-center">
          {timeLeft > 0 ? (
            <div className="text-accent-3">
              <Trans
                t={t}
                i18nKey="otp.otp-expiring-text"
                values={{ time: DateUtils.formatSeconds(timeLeft) }}
                components={{ Bold: <span className="font-medium" /> }}
              />{' '}
            </div>
          ) : (
            <div className="text-semantic-light-4">{t('otp.otp-expired-text')}</div>
          )}

          <div onClick={() => next('password')} className="mt-4 cursor-pointer font-medium hover:underline" data-cy="login-flow-back">
            {t('otp.buttons.back')}
          </div>
        </div>
      </div>

      <ModalContext.Provider value={{ open: appModalOpen, onClose: () => setAppModalOpen(false) }}>
        <ConfirmationModal
          title={t('otp.app-modal.heading')}
          description={t('otp.app-modal.text')}
          confirmText={t('otp.app-modal.done')}
          onConfirm={() => setAppModalOpen(false)}
        />
      </ModalContext.Provider>
    </>
  );
};

export default TOTPLogin;
